<template>
  <div v-if="isTest" class="status-cells">
    <Popper class="status-popper" hover arrow :content="status">
      <div>
        <div>
          <div class="status-icon" v-if="status === 'Тест не выдан'">
            <img src="@/assets/images/icons/clock1.svg" alt="Статус:Тест не выдан"/>
          </div>
          <div class="status-icon" v-else-if="status === 'Тест не пройден'">
            <img src="@/assets/images/icons/clock2.svg" alt="Статус:Тест не пройден"/>
          </div>
          <div class="status-icon"
               v-else-if="status === 'В процессе прохождения'">
            <img src="@/assets/images/icons/clock3.svg" alt="Статус:В процессе прохождения"/>
          </div>
          <div class="status-icon" v-else-if="status === 'Тест завершен'">
            <img src="@/assets/images/icons/clock4.svg" alt="Статус:Тест завершен"/>
          </div>
          <div class="status-icon" v-else-if="status === 'Выдано тестирование с полным отчетом'">
            <img src="@/assets/images/icons/clock5.svg" :alt="'Статус:' + status"/>
          </div>
          <div class="status-icon" v-else-if="status === 'Выдано тестирование с стандартным отчетом'">
            <img src="@/assets/images/icons/clock5.svg" :alt="'Статус:' + status"/>
          </div>
        </div>
      </div>
    </Popper>
    <div class="report_cell">
      <ReportsRender v-if="finished_tests && finished_tests.length"
                     :finished_tests="finished_tests"/>
    </div>
  </div>
  <div v-else>
    {{ status }}
  </div>
</template>

<script>
import ReportsRender from "@/components/Tables/CellRenderers/ReportRenderer.vue";

export default {
  name: "status-renderer",
  components: {ReportsRender},
  props: {
    status: String,
    finished_tests: Array,
    isTest: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style lang="scss" scoped>
.status-icon {
  width: 22px;
  height: 22px;
  margin: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.status-popper {
  display: block;
  font-size: 14px;
  cursor: help;
}

.status-cells {
  margin: auto;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

@media (max-width: 768px) {
  .status-cells {
    flex-flow: row-reverse;
  }
}
</style>
