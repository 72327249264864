<template>
  <button
      v-if="status === 'new' && id === profile.id"
      @click="getCourseLink()"
      class="btn btn-accent mr-10"
  >
    Пройти
  </button>
  <button v-else-if="currentUser && isPassing_test"
          @click="getReportLink()"
          class="btn btn-accent mr-10">
    Пройти
  </button>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "@vue/reactivity";
import {computed} from "@vue/runtime-core";
import {useRoute} from "vue-router";

export default {
  components: {},
  props: {
    status: String,
    id: Number,
    coursePassing: Object,
    code: String,
  },
  setup(props) {

    const store = useStore()
    const route = useRoute(),
        profile = computed(() => store.state.profile.profile),
        courseLink = computed(() => store.state.courseLink),
        currentCourse = ref([]),
        currentCourseLink = ref(""),
        currentUser = ref(false),
        isPassing_test = ref(false)
    const getCourseLink = () => {
      let actualCourse = props.coursePassing;
      currentCourse.value = courseLink.value.courses.find((course) => course.external_id === actualCourse.external_id);
      currentCourseLink.value = currentCourse.value.url
      window.open(currentCourseLink.value, "_blank")
    }

    const getReportLink = () => {
      window.open(`${process.env.VUE_APP_FRONT}/passing/${props.code}`, "_self")
    }

    if (route.params.product === "standard" || route.params.product === "full") {
      currentUser.value = props.id === profile.value.id
      if (currentUser.value) {
        isPassing_test.value = props.code !== undefined
      }
    }

    return {
      getCourseLink,
      profile,
      isPassing_test,
      currentUser,
      getReportLink
    };
  },
};
</script>
<style lang="scss">
.action-popper li {
  padding: 0;
}
</style>